<template>

  <div class="pbf">
  <template v-if="nutrient_data?.id">
   
    <BrandHead 
      :id="nutrient_data.id"
      :nm="nutrient_data.name"
      :nmalt="nutrient_data.name"
      :logo="nutrient_data.item_brand.logo_s"
      :link="nutrient_data.section"
      :link-shop="nutrient_data.link_shop"
      :rate="nutrient_data.item_stat?.avg_rate"
      :slogan="nutrient_data.slogan"
      :cnt_followers="nutrient_data.item_stat?.cnt_followers"
      :isfollow="nutrient_data?.is_follow"
      :type="type"
      :offical_user="nutrient_data.offical_user"
      :bread="[
        {name: $t('nutrients_view_link_text'), link: '/nutrients'},
        {name: nutrient_data.item_brand.name, link: '/nutrients/' + nutrient_data.item_brand.section},
        {name: nutrient_data.name, link: nutrient_data.section},
      ]"
      :brand_name="nutrient_data.item_brand.name"
      :brand_link="'/nutrients/' + nutrient_data.item_brand.section"
      @follow="follow(1)"
      @unfollow="follow(0)"
      >
      <UiTabs 
        :tabs="getTabs"
        :acttab="active_tab"
        />    
      </BrandHead>

    <div class="pb grid_width">  
      <NuxtPage 
        :data="nutrient_data"
        :id="nutrient_data.id"
        />
        
    </div>

    <BrandContextMenu 
      :is-follow="nutrient_data?.is_follow" 
      @follow="follow(1)"
      @unfollow="follow(0)"
      />
      

  </template> 
  
  <template v-else>
    <SkBrandOpened />
  </template> 

  </div>
</template>

<script setup lang="ts">

const route = useRoute();
const router = useRouter();
const {$api, $follow, $popup} = useNuxtApp()
var link = ref(route.params['id']);
var link2 = ref(route.params['id2']);
var active_tab = ref('info');
var type = ref('nutrient');



const getTabs = computed(() => {  

  var items = [];

  items.push({
    id: 'info',
    name: 'Info',
    link: nutrient_data.value?.link + '',          
    count: 0,
    icon: '',
  });

  if(nutrient_data.value?.item_stat?.cnt_reports){
    items.push({
      id: 'diaries',
      name: 'Diaries',
      link: nutrient_data.value?.link + '/diaries',
      count: nutrient_data.value?.item_stat?.cnt_reports,
      icon: '',
    });
  }

  if(nutrient_data.value?.item_stat?.cnt_growers){
    items.push({
      id: 'growers',
      name: 'Growers',
      link: nutrient_data.value?.link + '/growers',
      count: nutrient_data.value?.item_stat?.cnt_growers,
      icon: '',
    });
  }

  if(nutrient_data.value?.item_stat?.cnt_reviews){
    items.push({
      id: 'reviews',
      name: 'Reviews',
      link: nutrient_data.value?.link + '/reviews',
      count: nutrient_data.value?.item_stat?.cnt_reviews,
      icon: '',
    });
  }

  return items;

})  
 
const follow = function(state) {  
  $follow.toggle('product', nutrient_data.value.id, state, {
    type: 'product',
    id: nutrient_data.value.id,
    avatar: nutrient_data.value.avatar_small, 
    name: nutrient_data.value.name, 
    link: nutrient_data.value.link, 
  })
  .then((response) => {    
    nutrient_data.value.is_follow = state;
    if(nutrient_data.value.item_stat)
      nutrient_data.value.item_stat.cnt_followers += state ? 1 : -1;
  })
  .catch((error) => {
    $popup.error('Error' + error)
  });
}


const loadData = async function(nutrient, brand) {     
  const response = await $api.getUrl('/ref/products/' + nutrient, {
    brandsection: brand, 
  })  
  return response;  
}


const { pending: is_loading,  data: nutrient_data } = await useLazyAsyncData('nutrient_data_' + link2.value, async () => await loadData(link2.value, link.value))

 
watch( () => nutrient_data, () => {
  useHead(useNuxtApp().$head.getNutrient(nutrient_data.value));
}, {immediate: true, deep: true});
 
watch( () => router.currentRoute, () => {
  useHead(useNuxtApp().$head.getNutrient(nutrient_data.value));
}, {immediate: true, deep: true});


onBeforeUnmount(() => {
  nutrient_data.value = null;
})


  
</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
